import React, { useState } from 'react';
import LazyImage from './lazy-image';
import yfywPreview from './images/home_bg_2.jpg';
import homebg from './images/home_bg.jpg';
import crewPic from './images/crew_pic.jpg';
import classnames from 'classnames';
import { BrowserRouter as Router, Routes, Route, Link, Navigate } from 'react-router-dom';
import { SiInstagram, SiVimeo, SiImdb } from 'react-icons/si';
import './App.css';

import styles from './App.module.css';

const App = () => {
  const [activePage, setActivePage] = useState('home');
  return (
    <Router>
      <div className="App">
        <div className={styles.navbar}>
          <ul>
            <li><Link to="/" className={classnames({[styles.active]: activePage === 'home'})} onClick={() => setActivePage('home')}>Home</Link></li>
            <li><Link to="/work" className={classnames({[styles.active]: activePage === 'work'})} onClick={() => setActivePage('work')}>Work</Link></li>
            <li><Link to="/contact" className={classnames({[styles.active]: activePage === 'contact'})} onClick={() => setActivePage('contact')}>Contact</Link></li>
          </ul>
        </div>
        <main>
          <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/work" element={<Work />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/404" element={<NotFound />} />
              <Route
                  path="*"
                  element={<Navigate to="/" replace />}
              />
          </Routes>
        </main>
        <div className={styles.socialFooter}>
          <ul>
            <a href="http://instagram.com/branblackmon" target="_blank" rel="noopener noreferrer"><li><SiInstagram /></li></a>
            <a href="https://vimeo.com/branblackmon" target="_blank" rel="noopener noreferrer"><li><SiVimeo /></li></a>
            <a href="https://www.imdb.com/name/nm13708440" target="_blank" rel="noopener noreferrer"><li><SiImdb /></li></a>
          </ul>
        </div>
      </div>
    </Router>
  );
}

const Home = () => {
  return (
    <div className={styles.home}>
      <LazyImage src={homebg} />
      <div className={styles.bgOverlay}></div>
      <div className={styles.siteTitle}>
        <div className={styles.personName}>Brandon Blackmon</div>
        <div className={styles.personPosition}>Director</div>
      </div>
    </div>
  );
};

interface OneWorkProps {
  title: string;
  preview: string;
  year: string;
  runtime: string;
  destination: string;
}

const OneWork: React.FC<OneWorkProps> = ({ title, year, destination, preview }: OneWorkProps) => {
  return (
    <a href={destination} target="_blank" rel="noopener noreferrer" className={styles.oneWork}>
        <div className={styles.workOverlay}></div>
        <div className={styles.workTitle}>{title}</div>
        <div className={styles.previewHolder}>
          <LazyImage src={preview} />
        </div>
    </a>
  );
};



const Work: React.FC = () => {
  return (
    <div className={styles.works}>
      <OneWork title="You Forgot Your Wallet?" preview={yfywPreview} year="2022" runtime="00:14:06" destination="https://youforgotyourwallet.com" />
    </div>
  );
};

const Contact: React.FC = () => {
  return (
    <div className={styles.contact}>
      <LazyImage id="crewPick" src={crewPic} />
      <div id="contactContainer" className={styles.contactContainer}>
        <div className={styles.contactTitle}>Contact</div>
        <div className={styles.contactPerson}>brandon@brandonblackmon.com</div>
      </div>
    </div>
  );
};

const NotFound: React.FC = () => {
  return (
    <div className={styles.contact}>
      
    </div>
  );
};

export default App;
