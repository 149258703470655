import React, { useState } from 'react';
import { useInView } from 'react-intersection-observer';
import styles from './App.module.css';

interface LazyImageProps {
    src: string;
    id?: string;
}

const LazyImage: React.FC<LazyImageProps> = ({ src, id }: LazyImageProps) => {
    const [isImageLoaded, setIsImageLoaded] = useState(true);
    React.useEffect(() => {
        const image = new Image();
        image.src = src;
        if (!image.complete) {
            setIsImageLoaded(false);
            image.onload = () => setIsImageLoaded(true);
        }
        return () => {
            image.onload = null;
        };
    }, []);
    const [ref, inView] = useInView({
        triggerOnce: true
    });
    return (src && inView)
        ? <img id={id} className={styles.lazyImage} draggable={false} ref={ref} src={src} alt="" />
        : <span ref={ref} />;
};

export default LazyImage;
